import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import logo from "../images/logo.png"
import instagram from "../images/instagram.svg"
import { navigate } from "gatsby-link"
import { Link } from "gatsby"

const supportedLanguages = {
  fr: "/",
  en: "/en/",
}

function isActive({ isCurrent }) {
  return isCurrent ? { className: "active" } : null
}

const MainNavigation = props => {
  //console.log("Navigation", props)
  const lang = props.lang
  const location = props.location
  return (
    <Navbar bg="transparent" expand="md">
      <Navbar.Brand as={Link} to={lang === "fr" ? "/" : "/" + lang}>
        {/* <DVLogo
          alt="Duhesme & Versigny"
          style={{ width: "225px", maxWidth: "100%" }}
        /> */}
        <img
          src={logo}
          alt="Duhesme & Versigny"
          style={{ width: "225px", maxWidth: "100%" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <Nav.Link
            as={Link}
            to={`${supportedLanguages[lang]}`}
            activeClassName="active"
          >
            {lang === "fr" ? "projets" : "projects"}
          </Nav.Link>
          <Nav.Link
            as={Link}
            to={lang === "fr" ? "/a-propos" : "/en/about"}
            activeClassName="active"
          >
            {lang === "fr" ? "à propos" : "about"}
          </Nav.Link>
          <Nav.Link
            as={Link}
            to={`${supportedLanguages[lang]}contact`}
            activeClassName="active"
          >
            contact
          </Nav.Link>
          <Nav.Link
            href="https://www.instagram.com/dv_paris/"
            target="_blank"
            className="instagram"
          >
            <img
              src={instagram}
              alt="Instagram Duhesme & Versigny"
              style={{ width: "16px", height: "16px" }}
            />
          </Nav.Link>
          <Nav.Link
            className="d-none d-sm-none"
            onClick={() => {
              if (lang === "fr" && location.pathname !== "/a-propos") {
                navigate("/en" + location.pathname)
              } else if (lang === "fr" && location.pathname === "/a-propos") {
                navigate("/en/about")
              }
              return
            }}
            active={lang === "en" ? true : false}
          >
            eng
          </Nav.Link>
          <Nav.Link
            className="d-none d-sm-none"
            onClick={() => {
              if (lang === "en" && location.pathname !== "/en/about") {
                if (location.pathname === "/en") {
                  navigate(location.pathname.replace("/en", "/"))
                } else {
                  navigate(location.pathname.replace("en/", ""))
                }
              } else if (lang === "en" && location.pathname === "/en/about") {
                navigate(location.pathname.replace("/en/about", "/a-propos"))
              }

              return
            }}
            active={lang === "fr" ? true : false}
          >
            fr
          </Nav.Link>
          <div className="d-block d-sm-block mobile-lang">
            <Nav.Link
              onClick={() => {
                if (lang === "fr" && location.pathname !== "/a-propos") {
                  navigate("/en" + location.pathname)
                } else if (lang === "fr" && location.pathname === "/a-propos") {
                  navigate("/en/about")
                }
                return
              }}
              active={lang === "en" ? true : false}
            >
              eng
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                if (lang === "en" && location.pathname !== "/en/about") {
                  if (location.pathname === "/en") {
                    navigate(location.pathname.replace("/en", "/"))
                  } else {
                    navigate(location.pathname.replace("en/", ""))
                  }
                } else if (lang === "en" && location.pathname === "/en/about") {
                  navigate(location.pathname.replace("/en/about", "/a-propos"))
                }

                return
              }}
              active={lang === "fr" ? true : false}
            >
              fr
            </Nav.Link>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MainNavigation
