import * as React from "react"
import { graphql, Link } from "gatsby"
import ProgressiveImage from "react-progressive-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { AnimatePresence, motion } from "framer-motion"
import MainNavigation from "../components/mainNavigation"
import { Col, Row } from "react-bootstrap"

const mediaUrl = process.env.REACT_APP_UPLOADS_URL
//const mediaUrl = "http://cockpit.dv.fr/storage/uploads"

const transition = {
  duration: 0.6,
  ease: [0.43, 0.13, 0.23, 0.96],
  when: "afterChildren",
}
const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { transition },
  },
  exit: {
    filter: `invert()`,
    opacity: 0,
    transition: { transition },
  },
}

const PageTemplate = props => {
  //console.log(props)
  const data = props.data
  const location = props.location
  //console.log(data.cockpitPages)
  const post = data.cockpitPages
  const projects = data.allCockpitProjects.edges
  const types = data.allCockpitType.edges
  //console.log(projects)
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const [activeCard, setActiveCard] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [currentType, setCurrentType] = React.useState(null)
  const [projectsCollection, setProjectsCollection] = React.useState(null)
  if (!projectsCollection && projects.length) {
    setProjectsCollection(projects)
  }

  const filter = tag => {
    // console.log("tag", tag)
    setCurrentType(tag)
    if (tag === "all") {
      const filtered = projects
      setProjectsCollection(filtered)
    } else {
      // console.log(project.node.type.value)
      const filtered = projects.filter(project =>
        project.node.type.value.some(
          type =>
            "Cockpit__Type__" + type._id + "_" + props.pageContext.lang === tag
        )
      )
      // console.log(filtered)
      setProjectsCollection(filtered)
    }
  }

  //let isModal = false
  // We don't want to show the modal if a user navigates
  // directly to a post so if this code is running on Gatsby's
  // initial render then we don't show the modal, otherwise we
  // do.
  if (location.state && location.state.currentType && currentType === null) {
    // console.log(
    //   "window.___GATSBYGRAM_INITIAL_RENDER_COMPLETE",
    //   window.___GATSBYGRAM_INITIAL_RENDER_COMPLETE
    // )
    // console.log("ocation.state.thePosition", location.state.thePosition)
    if (
      typeof window !== `undefined` &&
      window.___GATSBYGRAM_INITIAL_RENDER_COMPLETE
    ) {
      setCurrentType(location.state.currentType)
      filter(location.state.currentType)
      if (location.state.thePosition) {
        window.scrollTo(0, location.state.thePosition)
      }
    }
  }
  // if (location.state && location.state.currentType && currentType === null) {
  //   setCurrentType(location.state.currentType)
  //   filter(location.state.currentType)
  //   props.history.replace(location.pathname, null)
  // }
  return (
    <Layout location={location} title={siteTitle}>
      <motion.div
        className="project"
        key={"Page"}
        variants={containerVariants}
        // initial={{
        //   y: 50,
        //   opacity: 0,
        //   transition: { transition },
        // }}
        // animate={{
        //   y: 0,
        //   opacity: 1,
        //   transition: { transition },
        // }}
        // exit={{
        //   y: 50,
        //   opacity: 0,
        //   transition: {
        //     ...transition,
        //     delay: activeCard === null ? 0 : 0,
        //   },
        // }}
      >
        <SEO
          title={
            post.title.value === "homepage"
              ? "Duhesme & Versigny"
              : post.title.value
          }
          description={post.content.value
            .slice(0, 150)
            .replace(/<\/?[^>]+(>|$)/g, "")}
          lang={props.pageContext.lang}
        />
        <MainNavigation lang={props.pageContext.lang} location={location} />
        <motion.div
          key={"text"}
          variants={containerVariants}
          initial={{
            y: 50,
            opacity: 0,
            transition: { transition },
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { transition },
          }}
          exit={{
            y: 50,
            opacity: 0,

            transition: {
              ...transition,
              delay: 0.8,
            },
          }}
        >
          <Row>
            <Col md={12}>
              <div
                className="page-content"
                dangerouslySetInnerHTML={{
                  __html: post.content.value,
                }}
                itemProp="articleBody"
              />
            </Col>
          </Row>
        </motion.div>
        {projectsCollection && props.pageContext.slug === "homepage" && (
          <>
            <Row>
              <Col md={12}>
                <motion.div key={"projects"} className="filters">
                  <motion.span
                    key={"all"}
                    initial={{
                      y: 50,
                      opacity: 0,
                    }}
                    animate={{
                      y: 0,
                      opacity: 1,
                      transition: {
                        ...transition,
                        delay: 0,
                      },
                    }}
                    exit={{
                      y: 50,
                      opacity: 0,
                      transition: {
                        ...transition,
                        delay: types.length / 10,
                      },
                    }}
                    transition={{ ...transition, delay: 0.55 }}
                    className={
                      currentType === "all" || currentType === null
                        ? "active"
                        : ""
                    }
                    onClick={() => filter("all")}
                  >
                    {props.pageContext.lang === "fr" ? "tous" : ""}
                    {props.pageContext.lang === "en" ? "all" : ""}
                  </motion.span>
                  {types &&
                    types.map((type, index) => (
                      <motion.span
                        key={index}
                        className={currentType === type.node.id ? "active" : ""}
                        onClick={() => filter(type.node.id)}
                        initial={{
                          y: 50,
                          opacity: 0,
                        }}
                        animate={{
                          y: 0,
                          opacity: 1,
                          transition: {
                            ...transition,
                            delay: (index + 1) / 10,
                          },
                        }}
                        exit={{
                          y: 50,
                          opacity: 0,
                          transition: {
                            ...transition,
                            delay: (types.length + 1 - index) / 10,
                          },
                        }}
                        //transition={{ ...transition, delay: 0.55 }}
                      >
                        {type.node.type.value}
                      </motion.span>
                    ))}
                </motion.div>
              </Col>
            </Row>
            <div
              key={"projects-cards"}
              //layout
              // variants={{
              //   hidden: { opacity: 0, scale: 0.8 },
              //   show: {
              //     opacity: 1,
              //     scale: 1,
              //     transition: {
              //       staggerChildren: 0.5,
              //     },
              //   },
              // }}
            >
              <Row style={{ minHeight: "300px" }}>
                {/* <AnimatePresence
                        //initial={false}
                        exitBeforeEnter
                      > */}
                {projectsCollection &&
                  projectsCollection.map((post, index) => (
                    <Col
                      key={`collection-${post.node.id}`}
                      // md={activeCard === null ? "4" : "12"}
                      md={4}
                      xs={12}
                      className={
                        activeCard === null
                          ? `project-card col-md-4 col-12 p-2 ${post.node.id}`
                          : `project-card col-md-4 col-12 p-2 ${post.node.id}`
                      }
                      //style={{ height: "200px" }}
                      onClick={() => {
                        //console.log("activeCard", activeCard)
                        //console.log("setactiveCard", post.node.id)
                        setActiveCard(post.node.id)
                        //return;
                      }}
                      // style={{
                      //   display:
                      //     activeCard === post.node.id || activeCard === null
                      //       ? "flex"
                      //       : "none",
                      // }}
                    >
                      <motion.div
                        key={`card-${post.node.id}`}
                        className="text-center"
                        style={{
                          height: "100%",
                          width: "100%",
                          minWidth: "100%",
                          textAlign: "center",
                          position: "relative",
                          padding: "0",
                          overflow: "hidden",
                          // transform: "scale(1)",
                          //overflow: "hidden",
                        }}
                        layout
                        initial="hidden"
                        animate="visible"
                        exit="pageExit"
                        transition={{
                          duration: 0.3,
                          layoutX: { duration: 0.3 },
                          layoutY: { delay: 0.2, duration: 0.3 },
                          ...transition,
                        }}
                        variants={{
                          hidden: {
                            scale: 0.8,
                            opacity: 0,
                          },
                          visible: {
                            scale: 1,
                            opacity: 1,
                            transition: {
                              delay: 0.4,
                              duration: 0.2,
                            },
                          },
                          pageExit: {
                            //filter: `invert()`,
                            //transform: "scale(2)",
                            //position: "fixed",
                            //top: "100px",
                            //x: "50%",
                            //layoutX: "50%",
                            //left: "0%",
                            //translateX: "-50%",
                            //transform: "translateX(-50%)",
                            //scale: 1.1,

                            opacity: 0,
                            //overflow: "auto",
                            //width: "100%",
                            // maxWidth: "none",
                            zIndex: 9,
                            transition: {
                              ...transition,
                              delay: 0.3,
                              duration: 1.2,
                              layoutX: { duration: 2.3 },
                              layoutY: { delay: 0.2, duration: 2.3 },
                              //...transition,
                            },
                          },
                        }}
                      >
                        {post.node.visuelHome && post.node.visuelHome.sizes && (
                          <Link
                            to={`projet/${post.node.title.slug}`}
                            state={{
                              currentType: currentType,
                              thePosition:
                                typeof window !== `undefined` &&
                                window.pageXOffset
                                  ? window.pageYOffset
                                  : null,
                            }}
                          >
                            {/* <motion.img
                                    key="image"
                                    style={{
                                      height: "100%",
                                      width: "auto",
                                      maxWidth: "100%",
                                    }}
                                    whileHover={{
                                      scale: 1.1,
                                    }}
                                    transition={transition}
                                    src={`http://cockpit.dv.fr/storage/uploads${post.visuelHome.sizes.preload.path}`}
                                  /> */}
                            <motion.div
                              whileHover={{
                                scale: 1.1,
                                zIndex: 1,
                                //filter: `invert()`,
                              }}
                              transition={transition}
                            >
                              <ProgressiveImage
                                src={`${mediaUrl}${post.node.visuelHome.sizes.medium.path}`}
                                placeholder={`${mediaUrl}${post.node.visuelHome.sizes.preload.path}`}
                              >
                                {src => (
                                  <img
                                    className="text-center img-fluid"
                                    key={`img-${post.node.id}`}
                                    style={{
                                      opacity: loading ? 0.6 : 1,
                                      // height: "100%",
                                      width: "100%",
                                      //maxWidth: "100%",
                                      // display:
                                      //   activeCard === post.node.id ||
                                      //   activeCard === null
                                      //     ? "block"
                                      //     : "none",
                                    }}
                                    // exit={{
                                    //   scale: 8,
                                    //   transition: { delay: 3 },
                                    // }}
                                    src={src}
                                    alt={post.node.title.value}
                                  />
                                )}
                              </ProgressiveImage>
                              <motion.div
                                className="hover-title"
                                whileHover={{ opacity: 0.8 }}
                                transition={transition}
                              >
                                {post.node.title.value}
                              </motion.div>
                            </motion.div>
                          </Link>
                        )}
                        {/* {post.visuelHome &&
                              post.visuelHome.path &&
                              !post.visuelHome.sizes && (
                                <Link
                                  to={`${supportedLanguages[lang]}projet/${post.title_slug}`}
                                >
                                  <motion.img
                                    key="image"
                                    style={{
                                      height: "100%",
                                      width: "auto",
                                      maxWidth: "100%",
                                    }}
                                    whileHover={{
                                      scale: 1.1,
                                    }}
                                    transition={transition}
                                    src={`http://cockpit.dv.fr/storage/uploads${post.visuelHome.path}`}
                                  />
                                </Link>
                              )} */}
                      </motion.div>
                    </Col>
                  ))}
              </Row>
            </div>
          </>
        )}
      </motion.div>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!, $lang: String!) {
    site {
      siteMetadata {
        title
      }
    }

    cockpitPages(title: { slug: { eq: $slug } }, lang: { eq: $lang }) {
      title {
        value
      }
      content {
        value
      }
    }

    allCockpitProjects(
      filter: { published: { value: { eq: true } }, lang: { eq: $lang } }
    ) {
      edges {
        node {
          id
          client {
            value {
              link
              display
            }
          }
          equipe {
            value
          }
          histoire {
            value
          }
          title {
            slug
            value
          }
          lang
          visuelHome {
            sizes {
              preload {
                path
              }
              medium {
                path
              }
            }
          }
          type {
            value {
              _id
            }
          }
          visuels {
            value {
              sizes {
                full {
                  path
                }
              }
            }
          }
        }
      }
    }
    allCockpitType(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          id
          type {
            value
          }
        }
      }
    }
  }
`
